const Nissan = {};
const video = $('.js-video');
let md = new MobileDetect(window.navigator.userAgent);
let mobile = false;

Nissan.mobile = {
    init: function() {
        let videos = $('video');
        $('video').remove();
        mobile = true;
        console.log('mobile!');
        $(document).on('click', 'video', function() {
            if (this.paused) {
                this.play();
                console.log('play');
                console.log(this);
            }
        });
        window.addEventListener("orientationchange", function() {
            if (mobile) {
                for (let i = 0; i < video.length; i++) {
                    videos[i].pause();
                    console.log('pause');
                }
            }
        }, false);
        //для айос хрома
        (function($) {
            $.fn.nodoubletapzoom = function() {
                $(this).bind('touchstart', function preventZoom(e) {
                    var t2 = e.timeStamp,
                        t1 = $(this).data('lastTouch') || t2,
                        dt = t2 - t1,
                        fingers = e.originalEvent.touches.length;
                    $(this).data('lastTouch', t2);
                    if (!dt || dt > 500 || fingers > 1) return; // not double-tap

                    e.preventDefault(); // double tap - prevent the zoom
                    // also synthesize click events we just swallowed up
                    $(this).trigger('click').trigger('click');
                });
            };
        })(jQuery);
        var bg = jQuery(".video-block");
          jQuery(window).resize("resizeBackground");
          function resizeBackground() {
              bg.height(jQuery(window).height());
          }
          resizeBackground();
    }
}

Nissan.video = {
    curQuestion: 1,
    correctAnswers: 0,
    init: function() {
        //start video
        var xhr = new XMLHttpRequest();
        xhr.open('GET', 'video/start-video.mp4', true);
        xhr.responseType = 'blob';
        xhr.addEventListener("progress", function(evt) {
            var percentComplete = Math.round(evt.loaded / evt.total * 100);
            $('.js-loading').html(percentComplete);
        }, false);
        xhr.onload = function(e) {
            if (this.status == 200) {
                $('body').addClass('loaded');
                var myBlob = this.response;
                var vid = URL.createObjectURL(myBlob);

                if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                    video[0].src = 'video/start-video.mp4';
                } else {
                    video[0].src = vid;
                }

            }
        }

        xhr.send();

        video[0].ontimeupdate = function() {
            let curTime = Math.round(video[0].currentTime);
            if (curTime == 12) {
                Nissan.video.startScreen();
            }
        };

        //start test
        $('.js-play').on('click', function() {
            video[0].currentTime = 12;
            $('.js-start-screen').removeClass('show').hide();
            $('.js-article-list').hide();
            $('.js-footer').removeClass('footer-show').addClass('hide-border');
            $('.js-video-list').removeClass('list-shadow');
            $('.js-progressbar').addClass('show');
            $('.js-back').show();
            $('.js-footer-hover').hide();
            Nissan.video.playing(Nissan.video.curQuestion, video);
        });
        //mute video
        $('.js-mute').on('click', function() {
            let btn = $(this);
            btn.toggleClass('muted');
            if ($("video").prop('muted')) {
                video.prop('muted', false);
            } else {
                video.prop('muted', true);
            }
        });
        //stop intro
        $('.js-intro').on('click', function() {
            video[0].currentTime = 12;
            Nissan.video.startScreen();
        });
        //hint
        $('.js-hint').on('click', function() {
            let hintVideo = $("[data-hint=" + Nissan.video.curQuestion + "]");
            Nissan.video.hintVideo(Nissan.video.curQuestion, hintVideo);
            ga("send", "event", "test", "get hint", "num");
        });
        //answer
        $('.js-answer').on('click', function() {
            let btn = $(this);
            let answer = parseInt(btn.attr('data-answer'));
            Nissan.video.answers(answer, Nissan.video.curQuestion, Nissan.video.correctAnswers);
        });
        //if wrong answer
        $('.js-next').on('click', function() {
            $('.js-error-screen').removeClass('show');
            Nissan.video.hideQuestion(Nissan.video.curQuestion);
            Nissan.video.playing(Nissan.video.curQuestion);
        });
        //popup
        $('.js-text').on('click', function() {
            $('.js-popups').show();
            $("[data-question=" + Nissan.video.curQuestion + "]").find(".js-answer").removeClass('animated-questions');
            $("[data-text=" + Nissan.video.curQuestion + "]").show().siblings().hide();
            $(".js-buttons").hide();
        });
        $('.js-close-popup').on('click', function() {
            $('.js-popups').hide();
            $(".js-buttons").show();
            $("[data-question=" + Nissan.video.curQuestion + "]").find(".js-answer").addClass('animated-questions');
        });
        //go to start
        $('.js-back').on('click', function() {
            $(this).hide();
            $('.js-buttons').hide();
            $('.js-video-list').removeClass('shadowed');
            $('.js-progressbar').removeClass('show');
            $('.js-answer').removeClass('animated-questions');
            $('.js-article-list').show();
            $('.js-footer-hover').show();
            $("[data-article]").hide();
            $('.js-result-screen').removeClass('show');
            $('.footer').addClass('js-footer');
            $('.js-footer').removeClass('hide-border').removeClass('footer-finish');
            $("[data-question=" + Nissan.video.curQuestion + "]").removeClass('show');
            $('.progressbar__item').removeClass('done').find('[data-progress]').css('width', 0);
            Nissan.video.curQuestion = 1;
            Nissan.video.correctAnswers = 0;
            $('.js-start-screen').show().addClass('show');
            video[Nissan.video.curQuestion].pause();
            video[Nissan.video.curQuestion].currentTime = 0;
            video[0].currentTime = 12;
            video[0].pause();
            $("[data-hint=" + Nissan.video.curQuestion + "]")[0].pause();
            $(video[0]).removeClass('hidden-video').siblings().addClass('hidden-video');
        });
    },

    playing: function(curQuestion) {
        let video = $('.js-video');
        let curVideo = video[curQuestion];
        let duration = curVideo.duration;
        let curProgress = 0;
        $('.js-back').show();
        $(curVideo).removeClass('hidden-video').siblings().addClass('hidden-video');
        //curVideo.playbackRate = 3.0;
        curVideo.play();
        curVideo.onplay = function() {
            console.log('The video has started to play');
        };
        curVideo.onplaying = function() {
            console.log('The video is now playing');
        };
        curVideo.onstalled = function() {
            console.log("Media data is not available");
        };
        curVideo.ontimeupdate = function() {
            let curTime = curVideo.currentTime;
            curProgress = 100 * curTime / duration;
            $("[data-progress=" + curQuestion + "]").css('width', curProgress + "%");

            if (curTime >= (duration - 2) && curTime <=  (duration - 1)) {
                if (curQuestion == 6) {
                    Nissan.video.showResult();

                } else {
                    Nissan.video.showQuestion(curQuestion);
                }
            }

            if (curTime == duration) {
                curVideo.pause();
                $("[data-progress=" + curQuestion + "]").parent().addClass('done');
            }
        };
    },
    startScreen: function() {
        Nissan.sound.play('whoosh_intro');
        video[0].pause();
        $('.js-intro').hide();
        $('.js-start-screen').addClass('show');
        $('.nissan-logo').addClass('animated-logo');
        $('.js-start-screen').addClass('animated-start');
        $('.footer__share').addClass('animated-footer');
        $('.js-article-list').addClass('animated-plus');
        $('.footer').addClass('animated-border js-footer');
        $('.js-footer-logo').addClass('animated-murano');
    },
    showQuestion: function(curQuestion) {
        $("[data-question=" + curQuestion + "]").addClass('show');
        $('.js-video-list').addClass('shadowed');
        $("[data-question=" + curQuestion + "]").find(".js-answer").addClass('animated-questions');
        $(".js-buttons").addClass('animated-buttons');
        $(".js-buttons").show();
    },

    hideQuestion: function(curQuestion) {
        $("[data-question=" + curQuestion + "]").removeClass('show');
        $('.js-video-list').removeClass('shadowed');
    },

    showResult: function() {
        ga("send", "event", "test", "finish");
        $('.js-video-list').addClass('shadowed');
        $('.js-result-screen').addClass('show');
        if(Nissan.video.correctAnswers == 0){
          Nissan.video.correctAnswers = 1;
        }
        $("[data-result=" + Nissan.video.correctAnswers + "]").show();
        $('.js-footer').addClass('footer-finish').removeClass('js-footer');
        if (Nissan.video.correctAnswers <= 3) {
            $("[data-article='1']").show();
        } else {
            $("[data-article='2']").show();
        }
    },

    answers: function(answer, curQuestion, correctAnswers) {
        ga("send", "event", "test", "answer question", curQuestion);
        switch (curQuestion) {
            case 1:
                switch (answer) {
                    case 3:
                        right();
                        break;
                    default:
                        wrong();
                }
                break;
            case 2:
                switch (answer) {
                    case 1:
                        right();
                        break;
                    default:
                        wrong();
                }
                break;
            case 3:
                switch (answer) {
                    case 3:
                        right();
                        break;
                    default:
                        wrong();
                }
                break;
            case 4:
                switch (answer) {
                    case 2:
                        right();
                        break;
                    default:
                        wrong();
                }
                break;
            case 5:
                switch (answer) {
                    case 3:
                        right();
                        break;
                    default:
                        wrong();
                }
                break;
            default:
                console.log('error');
        }

        function right() {
            Nissan.video.correctAnswers = correctAnswers + 1;
            Nissan.video.curQuestion = curQuestion + 1;
            $(".js-answer").removeClass('animated-questions');
            $(".js-buttons").removeClass('animated-buttons');
            $(".js-buttons").hide();
            Nissan.video.hideQuestion(curQuestion);
            Nissan.video.playing(Nissan.video.curQuestion);
        }

        function wrong() {
            Nissan.video.curQuestion = curQuestion + 1;
            Nissan.video.hideQuestion(curQuestion);
            $('.js-error-screen').addClass('show');
            $(".js-buttons").removeClass('animated-buttons');
            $(".js-buttons").hide();
        }
    },

    hintVideo: function(curQuestion, hintVideo) {
        hintVideo.removeClass('hidden-video').siblings().addClass('hidden-video');
        Nissan.video.hideQuestion(curQuestion);
        $(".js-buttons").hide();
        hintVideo[0].currentTime = 0;
        hintVideo[0].play();
        let duration = Math.round(hintVideo[0].duration);
        hintVideo[0].ontimeupdate = function() {
            let curTime = Math.round(hintVideo[0].currentTime);
            if (curTime == (duration)) {
                hintVideo[0].pause();
                Nissan.video.showQuestion(curQuestion);
                $(".js-buttons").show();
            }
        };
    }
};

Nissan.share = {

    sharingPopupShow: function(clickedElem) {
        var network = clickedElem.attr('data-name'),
            networkUrl = clickedElem.attr('data-link'),
            pageUrl = window.location.href,
            shareUrl = clickedElem.attr('data-url'),
            text,
            url = '';
        if (shareUrl) {
            text = $("meta[property='og:title']").attr('content');
        } else {
            text = $("meta[property='og:description']").attr('content');
        }
        if (network == 'vk') {
            if (shareUrl) {
                url = networkUrl + pageUrl + shareUrl + '&noparse=true';
            } else {
                url = networkUrl + pageUrl + '/&noparse=true';
            }
        }
        if (network == 'tw') {
            if (shareUrl) {
                pageUrl = text + ': ' + pageUrl + shareUrl;
                url = networkUrl + pageUrl;
            } else {
                pageUrl = text + ': ' + pageUrl;
                url = networkUrl + pageUrl;
            }
        } else if (network == 'fb') {
            if (shareUrl) {
                url = networkUrl + pageUrl + shareUrl;
            } else {
                url = networkUrl + pageUrl;
            }
        } else if (network == 'go') {
            if (shareUrl) {
                url = networkUrl + pageUrl + shareUrl;
            } else {
                url = networkUrl + pageUrl;
            }
        }

        window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
    },

    init: function() {
        $('.js-share').on('click', function(e) {
            e.preventDefault();
            var clickedElem = $(this);
            Nissan.share.sharingPopupShow(clickedElem);
        });
    }
};

Nissan.footer = {
    init: function() {
        $(document).on('mouseenter ', '.js-footer', function() {
            $('.js-footer').addClass('footer-show');
            $('.js-video-list').addClass('list-shadow');
            $('.js-question').hide();
            $('.js-buttons').hide();
        });
        $(document).on('mouseleave', '.js-footer', function() {
            $('.js-footer').removeClass('footer-show');
            $('.js-video-list').removeClass('list-shadow');
            $('.js-question').show();
            $('.js-buttons').show();
        });
    }
};

Nissan.sound = {
    init: function() {
        const sounds = $('audio');
        const t = this;
        $("[data-play]").on('click', function() {
            let name = $(this).attr('data-play');
            t.play(name)
        });
        $('.js-answer').on('mouseenter', function() {
            t.play('click2');
        });
    },
    play: function(name) {
        $("[data-sound=" + name + "]")[0].play();
    }
}

Nissan.rbc = {
    $header: $('.js-header'),
    $headerOpen: $('.js-header-open'),
    showMenu: function() {
        this.$header.addClass('active');
    },
    hideMenuTimeout: false,
    hideMenu: function() {
        var t = this;
        clearTimeout(this.hideMenuTimeout);
        this.hideMenuTimeout = setTimeout(function() {
            t.$header.removeClass('active');
        }, 1000);
    },
    init: function() {
        var t = this;
        t.$headerOpen.on('click', function() {
            t.showMenu();
            return false;
        });
        t.$header.on('mouseover', function() {
            clearTimeout(t.hideMenuTimeout);
        });
        t.$header.on('mouseleave', function() {
            t.hideMenu();
        });
    }
}

Nissan.promoVideo = {
    init: function() {
        let t = this;
        $('.nissan-logo').addClass('animated-logo');
        $('.js-promo-play').on('click', function() {
            let btn = $(this);
            let video = btn.siblings('video')[0];
            btn.toggleClass('playing');
            if (video.paused) {
                video.play();
                t.play(video, btn);
            } else {
                video.pause();
            }
        });
    },
    play: function(video, btn) {
        video.ontimeupdate = function() {
            let curTime = video.currentTime;
            if (curTime == video.duration) {
                btn.removeClass('playing');
            }
        }
    }
}

Nissan.counters = {
    init: function() {

        $('.ga-result-share').on('click', function() {
            let soc = $(this).attr('data-name');
            ga("send", "event", "share test", "clicked share button", soc);
        });

        $('.ga-share').on('click', function() {
            let soc = $(this).attr('data-name');
            ga("send", "event", "share", "clicked share button", soc);
        })

        $('.ga-start').on('click', function() {
            ga("send", "event", "test", "start");
        });
        $('.ga-murano').on('click', function() {
            ga("send", "event", "murano more", "clicked link");
        });
        $('.ga-test').on('click', function() {
            let url = $(this).attr('href');
            ga("send", "event", "test-drive", "clicked button", url);
        });
        $('.ga-nissan').on('click', function() {
            let url = $(this).attr('href');
            ga("send", "event", "brand button", "clicked header", url);
        });

        function seconds() {
            ga("send", "event", "page", "spent_on_page_15_sec");
        }
        setTimeout(seconds, 15000);

    },
};


$(document).ready(function() {
    Nissan.share.init();
    if (md.mobile()) {
        Nissan.mobile.init();
    }
    if (video.length > 1) {
        Nissan.video.init();
    } else {
        Nissan.promoVideo.init();
    }
    Nissan.sound.init();
    Nissan.rbc.init();
    Nissan.footer.init();
    Nissan.counters.init();
});
